import React from "react";
import Layout from "../components/layout"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap';
import PostSlotPage from "../components/PostSlotPage";
import styled from "styled-components";
import Seo from "../components/Seo"

const BlogRelate = styled.div`
    border-bottom: 3px solid #720000;
    margin-bottom: 15px;
    h3 {
        font-size: 28px;
        margin-bottom: 15px;
    }
`

const SlotPage = ({pageContext}) => {
    const getphoto = pageContext.featuredImage === null 
        ? null 
        : pageContext.featuredImage.node.localFile.childImageSharp
    const image = getImage(getphoto)
    const imageSeo = pageContext.featuredImage.node.localFile.publicURL
    return (
    <Layout>
        <Seo 
            title={pageContext.seo.title}
            description={pageContext.seo.metaDesc}
            image={"https://kickoffx999.com" + imageSeo}
            url={"https://kickoffx999.com" + pageContext.seo.canonical}
            canonical={"https://kickoffx999.com" + pageContext.seo.canonical}
        />
        <StaticImage src="../images/hero-banner.png" alt="hero-banner" />
        <Container className="py-5">
            <Row>
                <Col md={12}>
                    <BlogRelate>
                        <h3>สล็อตและเกมส์ <span>สำหรับลูกค้าเรา</span></h3>
                    </BlogRelate>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/ot.jpg" alt="ot-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/pug.jpg" alt="pug-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius"src="../images/slot/v2022/qs.jpg" alt="qs-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/tk.jpg" alt="tk-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/hak.jpg" alt="hak-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/ds.jpg" alt="ds-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/fug.jpg" alt="fug-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/bpg.jpg" alt="bpg-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/bole-slot.jpg" alt="bole-slot-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/rlx.jpg" alt="rlx-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/nlc.jpg" alt="nlc-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/pg.jpg" alt="pg-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/joker.jpg" alt="joker-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/cq9.jpg" alt="cq9-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/evo.jpg" alt="evo-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/goldy.jpg" alt="goldy-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/jdb.jpg" alt="jdb-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/slot/v2022/jili.jpg" alt="jili-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage src="../images/slot/v2022/netent.jpg" alt="netent-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage src="../images/slot/v2022/playstar.jpg" alt="playstar-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage src="../images/slot/v2022/pmsl.jpg" alt="pmsl-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage src="../images/slot/v2022/rt.jpg" alt="rt-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage src="../images/slot/v2022/sm.jpg" alt="sm-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage src="../images/slot/v2022/spade.jpg" alt="spade-01" />
                </Col>
            </Row>
        </Container>
        <Container className="pb-5">
            <h1 className="pb-3" dangerouslySetInnerHTML={{__html: pageContext.title}} />
                <GatsbyImage image={image} />
            <div className="pt-3" dangerouslySetInnerHTML={{__html: pageContext.content}} />
        </Container>
        <Container>
            <Row>
                <Col md={12}>
                    <BlogRelate>
                        <h3>บทความที่เกี่ยวข้อง</h3>
                    </BlogRelate>
                </Col>
            </Row>
            <PostSlotPage slug={pageContext.slug} />
        </Container>
    </Layout>
)};

export default SlotPage;